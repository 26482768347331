<template>
  <div id="app" :class="['app ' + $route.name]">
    <transition name="fade">
      <div id="page" class="page" v-if="pageLoaded">
        <Header @scrollToPrice="scrollToPrice"/>
        <router-view :currency="currency" @login="login" @forgotPass="forgotPass" @registration="registration" @scrollToPrice="scrollToPrice" @changeIslandToBuy="changeIslandToBuy" @changeIslandToBuyOptions="changeIslandToBuyOptions" :optionsToBuyFinal="optionsToBuyFinal" @optionsTotalPriceChange="optionsTotalPriceChange" @optionsToBuyFinalChange="optionsToBuyFinalChange" :zeroOptionsChosen="zeroOptionsChosen" @sendContactForm="sendContactForm" :totalBalance="totalBalance" :isAuth="isAuth"/>
        <Footer/>
        <transition name="fade">
          <ReadyModal v-if="readyModalVisible"/>
        </transition>
        <transition name="fade">
          <ReadyModalProducts v-if="readyModalProductsVisible" @nicknameChange="nicknameChange"/>
        </transition>
        <transition name="fade">
          <AddExistingIslandModal v-if="addExistingIslandModalVisible" @hostChange="hostChange" @portChange="portChange"/>
        </transition>
        <transition name="fade">
          <TopUpModal v-if="topUpModalVisible"/>
        </transition>
        <div class="modal modal-overlay" v-if="cookesModalIsVisible">
          <div class="cookies-modal">
            <div class="title">Cookie Information</div>
            <div class="desc chekbox-label">
              Our website relies on cookies to operate smoothly and enhance your browsing experience. Essential cookies are automatically enabled for core functions. We also use other types of cookies, which you can control through your preferences. Learn more in our <a class="title" @click="goToPage('cookie')">Cookie Policy</a>.
            </div>
            <button class="button blue" @click="acceptCookies">Confirm & Proceed</button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import ReadyModal from './components/ReadyModal.vue'
import ReadyModalProducts from './components/ReadyModalProducts.vue'
import AddExistingIslandModal from './components/AddExistingIslandModal.vue'
import TopUpModal from './components/TopUpModal.vue'
import {scroller} from 'vue-scrollto/src/scrollTo'
import {loadScriptsFromString} from "@/utils/load_scripts";
import i18n from "@/i18n";

 

export default {
  name: 'app',
  components: {
    Header,
    Footer,
    ReadyModal,
    ReadyModalProducts,
    TopUpModal,
    AddExistingIslandModal
  },
  data: function() {
    return {
      nav: [
        {
          title: 'HOME',
          path: '/'
        },
        {
          title: 'CONTACT US',
          path: '/contact-us'
        },
        {
          title: 'FAQ',
          path: '/faq'
        }
      ],
     pageLoaded: false,
     readyModalVisible: false,
     readyModalProductsVisible: false,
     topUpModalVisible: false,
      isAuth: false,
      error: '',
      successMessage: '',
      textPageTitle: '',
      name:'',
      textPageHeader: '',
      textPageContent: '',
      orderHistory: '',
      depositHistory: '',
      imgDomain: '',
      textPageList: '',
      currency: '',
      islandToBuy: {},
      islandToBuyOptions: {},
      islands: [],
      products: [],
      myIslands: [],
      chosenIslandProducts: [],
      optionsTotalPrice: null,
      optionsToBuyFinal: [],
      nickname: '',
      addExistingIslandModalVisible: false,
      host: '',
      port: '',
      totalBalance: '',
      zeroOptionsChosen: false,
      socialLinks: [],
      minTopUpAmount: null,
      settings: {},
      cookesModalIsVisible: false
    }
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
      if (this.isAuth) {
        this.getOrderHistory();
      }
      this.clearError();
    }
  },
  methods: {
    fetchTranslations() {
      this.$http.get(process.env.VUE_APP_API + 'localizations')
          .then((res) => {
            i18n.setLocaleMessage('en', res.data)
            i18n.locale = 'en';
          })
    },
    getGoogleTagManagerSnippet() {
      const self = this;

      return this.$http.get(process.env.VUE_APP_API + 'settings')
      .then((res) => {
        self.minTopUpAmount = res.data.payload.min_top_up_amount;
        const snippet = res.data.payload.google_tag;
        self.settings = res.data.payload;

        if (snippet) {
          loadScriptsFromString(snippet);
        }
      })
      .catch((error) => {
        console.error("Error fetching Google Tag Manager snippet:", error);
      });
    },
    nicknameChange(user) {
      this.nickname = user
    },
    hostChange(host) {
      this.host = host
    },
    portChange(port) {
      this.port = port
    },
    openAddExistingIslandModal() {
      this.addExistingIslandModalVisible = true;
    },
    closeAddExistingIslandModal() {
      this.successMessage = '';
      this.error = '';
      this.addExistingIslandModalVisible = false;
    },
    optionsToBuyFinalChange(items) {
      this.optionsToBuyFinal = items
    },
    optionsTotalPriceChange(sum) {
      this.optionsTotalPrice = sum
    },
    clearError() {
      this.error = '';
    },
    openReadyModalProducts() {
      this.readyModalProductsVisible = true;
    },
    closeReadyModalProducts() {
      this.successMessage = '';
      this.error = '';
      this.readyModalProductsVisible = false;
    },
    openReadyModal() {
      this.readyModalVisible = true;
    },
    closeReadyModal() {
      this.successMessage = '';
      this.error = '';
      this.readyModalVisible = false;
    },
    openTopUpModal() {
      this.topUpModalVisible = true;
    },
    closeTopUpModal() {
      this.topUpModalVisible = false;
    },
    login(data) {
      let self = this;
      this.$http.post(process.env.VUE_APP_API + 'login', data)
      .then((res) => {
        if (res.data.status == "OK") {
          this.getName();
          self.isAuth = true;
          this.clearError();
          this.getOrderHistory();
          this.getMyIslands();
          this.$router.push({path: '/profile/my-islands'});
        } else {
          this.error = res.data.message
        }
      })
      .catch((res) => {
          this.error = res.response.data.message;
      })
    },
    forgotPass(data) {
      let self = this;
      this.$http.post(process.env.VUE_APP_API + 'user/reset-password', data)
      .then((res) => {
        if (res.data.status == "OK") {
          this.successMessage = 'Success';
          this.error = ''
          setTimeout(()=> {
            self.successMessage = '';
          }, 2000)
        } else {
          this.error = res.response.data.message
        }
      })
      .catch((error) => {
        this.error = error.response.data.message
      })
    },
    registration(data) {
      let self = this;
      if (data.password !== data.passConfirm) {
        this.error = 'Password confirmation failed!';
      } else {
        this.$http.post(process.env.VUE_APP_API + 'register', data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.clearError();
            this.getName();
            this.getOrderHistory();
            this.getMyIslands();
            self.isAuth = true;
            self.$router.push({path: '/profile/my-islands'});
          } else {
            this.error = res.data.message
          }
        })
        .catch((res) => {
            this.error = res.response.data.message;
        })
      }
    },
    logout() {
      this.$http.post(process.env.VUE_APP_API + 'logout')
      .then(() => {
        if (this.$route.name != 'Home') {
          this.$router.push({ path: '/' })
        }
        this.isAuth = false;
        this.$router.push({path: '/'});
      })
      .catch((res) => {
        if(res.response.status === 401) {
          console.log(res);
        }
      })
    },
    getOrderHistory() {
      this.$http.get(process.env.VUE_APP_API + 'purchase/history')
      .then((res) => {
        this.orderHistory = res.data.payload
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignIn()
        }
      })
      this.$http.get(process.env.VUE_APP_API + 'orders/history')
      .then((res) => {
        this.depositHistory = res.data.payload
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignIn()
        }
      })
    },
    formatDate(num) {
      let objectDate = new Date(num);
      let day = objectDate.getDate();
      if (day < 10) {
          day = '0' + day;
      }
      let month = objectDate.getMonth() + 1;
      if (month < 10) {
          month = `0${month}`;
      }
      let year = objectDate.getFullYear();

      function padTo2Digits(x) {
        return String(x).padStart(2, '0');
      }
      let hoursAndMinutes = padTo2Digits(objectDate.getHours()) + ':' + padTo2Digits(objectDate.getMinutes());
      let format = day + "." + month + "." + year + ' ' + hoursAndMinutes;

      return format
    },
    goToPrivacy() {
      let pageId = '';
      for (let i = 0; i < this.textPageList.length; i++) {
        if (this.textPageList[i].is_privacy) {
          pageId = this.textPageList[i].id;
          this.$router.push({path: '/pages/' + pageId, params: {data: pageId}})
          this.getTextPageContent(pageId);
        } 
      }
    },
    goToTerms() {
      let pageId = '';
      for (let i = 0; i < this.textPageList.length; i++) {
        if (this.textPageList[i].is_terms) {
          pageId = this.textPageList[i].id;
          this.$router.push({path: '/pages/' + pageId, params: {data: pageId}})
          this.getTextPageContent(pageId);
        } 
      }
    },
    cookiesModal() {
      const cookiesAccepted = localStorage.getItem('cookiesAccepted');

      if (!cookiesAccepted) {
          this.cookesModalIsVisible = true;
      }
    },
    declineCookies() {
      this.cookesModalIsVisible = false;
    },
    acceptCookies() {
      localStorage.setItem('cookiesAccepted', 'true');
      this.cookesModalIsVisible = false;
    },
    goToPage(item) {
      let newItem;
      if (item == 'cookie') {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes('cookie')) {
            newItem = this.textPageList[i].id;
          } 
        }
      } else {
        newItem = item.id
      }
      this.$router.push({path: '/pages/' + newItem, params: {data: newItem}})
      this.getTextPageContent(newItem);
    },
    getTextPageContent(id) {
      this.textPageTitle = '';
      this.textPageHeader = '';
      this.textPageContent = '';
      this.$http.get(process.env.VUE_APP_API + 'static-pages/' + id)
      .then((res) => {
        this.textPageTitle = res.data.payload.title;
        this.textPageContent = res.data.payload.content;
        this.textPageHeader = res.data.payload.header;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignIn()
        }
      })
    },
    getTextPages() {
      this.$http.get(process.env.VUE_APP_API + 'static-pages')
      .then((res) => {
        this.textPageList = res.data.payload
      })
      .catch((res) => {
         if(res.response.status === 401) {
          this.openSignIn()
        }
      })
    },
    getSocialLinks() {
      return this.$http.get(process.env.VUE_APP_API + 'social-links')
          .then((res) => {
            this.socialLinks = res.data.payload;
          })
          .catch((res) => {
            if(res.response.status === 401) {
              this.openSignIn()
            }
          })
    },
    openSignIn() {
      this.error = '';
      this.$router.push({path: '/login'});
    },
    openSignUp() {
      this.$router.push({path: '/registration'});
    },
    openRecover() {
      this.$router.push({path: '/recover'});
    },
    scrollToPrice() {
      if (this.$route.name != 'Home') {
        this.$router.push({path: '/'});
      }
      setTimeout(()=> {
        const firstScrollTo = scroller()
        firstScrollTo('#price')
      }, 200)
    },
    changeIslandToBuy(item) {
      this.islandToBuy = item;
    },
    changeIslandToBuyOptions(item) {
      this.islandToBuyOptions = item;
      this.getIslandProducts();
    },
    buyIsland() {
      let data = {
        "size": this.islandToBuy.size
      }
      this.$http.post(process.env.VUE_APP_API + 'purchase/container', data)
      .then((res) => {
        if (res.data.status == "OK") {
          this.successMessage =  'Success';
          this.getMyIslands();
          this.getBalance();
          this.$router.push({path: '/profile/my-islands'});
        } else {
          this.error = res.data.message
        }
      })
      .catch((res) => {
        this.error = res.response.data.message
      })
    },
    addExistingIsland() {
      let data = {
        "host": this.host,
        "port": this.port
      }
      this.$http.post(process.env.VUE_APP_API + 'user/containers/attach', data)
      .then((res) => {
        if (res.data.status == "OK") {
          this.successMessage =  'Success';
          this.getMyIslands();
          this.$router.push({path: '/profile/my-islands'});
        } else {
          this.error = res.data.message
        }
      })
      .catch((res) => {
        this.error = res.response.data.message
        console.log(res)
      })
    },
    getIslandsToBuy() {
      this.$http.get(process.env.VUE_APP_API + 'containers')
      .then((res) => {
        this.islands = res.data.payload
        this.islandToBuy = this.islands[0]
      })
      .catch(() => {
         
      })
    },
    getMyIslands() {
      this.$http.get(process.env.VUE_APP_API + 'user/containers')
      .then((res) => {
        console.log(res)
        this.myIslands = res.data.payload
      })
      .catch(() => {
      })
    },
    getProducts() {
      this.$http.get(process.env.VUE_APP_API + 'products/?type=options')
      .then((res) => {
        console.log(res)
        this.products = res.data.payload;
      })
      .catch((res) => {
          if(res.response.status === 401) {
            this.openSignIn()
          }
      })
    },
    getIslandProducts() {
      this.$http.get(process.env.VUE_APP_API + 'purchase/products/list?container_id=' + this.islandToBuyOptions.id)
      .then((res) => {
        this.chosenIslandProducts = res.data.payload;
      })
      .catch((res) => {
          if(res.response.status === 401) {
            this.openSignIn()
          }
      })
    },
    buyProducts() {
      this.zeroOptionsChosen = false;
      let optionIds = [];
      for (let i = 0; i < this.optionsToBuyFinal.length; i++) {
        optionIds.push(this.optionsToBuyFinal[i].id)
      }
      let data = {
        "nickname": this.nickname,
        "container_id": this.islandToBuyOptions.id,
        "product_ids": optionIds
      }
      this.$http.post(process.env.VUE_APP_API + 'purchase/product', data)
      .then((res) => {
        console.log(res)
        if (res.data.status == "OK") {
          this.successMessage = 'Success';
          this.$router.push({path: '/profile/my-islands'});
          this.error = '';
          this.optionsToBuyFinal = [];
          this.chosenIslandProducts = [];
          this.islandToBuyOptions = [];
          this.zeroOptionsChosen = true;
          this.getBalance();
        } else {
          this.error = res.response.data.message
        }
      })
      .catch((res) => {
          this.error = res.response.data.message
      })
    },
    sendContactForm(data) {
      this.$http.post(process.env.VUE_APP_API + 'contact-us', data)
      .then((res) => {
        if (res.data.status == "OK") {
          let self = this;
          this.successMessage = 'Thank you your message has been sent';
          this.error = ''
          setTimeout(()=> {
            self.successMessage = '';
          }, 2000)
        } else {
          this.error = res.data.message
        }
      })
      .catch((res) => {
        this.error = res.response.data.message
      })
    },
    getBalance() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
        this.totalBalance = res.data.balance;
      })
      .catch(() => {
        
      })
    },
    getName() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
        this.name = res.data.name;
        this.nickname = res.data.nickname;
      })
      .catch(() => {
        
      })
    }
  },
  mounted: async function() {
    this.fetchTranslations();
    this.currency = process.env.VUE_APP_CURRENCY;
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.pageLoaded = true;
    let self = this;
    
    await self.getGoogleTagManagerSnippet();

    self.$http.get(process.env.VUE_APP_API + 'is-auth')
    .then((res) => {
        console.log('auth: ' + res.data.authenticated);
        self.isAuth = res.data.authenticated

        if (res.data.authenticated) {
          self.getOrderHistory();
        }
    })
    .catch((res) => {
        if(res.response.status === 401) {
          this.openSignIn()
        }
    })

    this.getName();
    this.cookiesModal();
    this.getProducts();
    this.getTextPages();
    this.getIslandsToBuy();
    this.getMyIslands();
    this.getSocialLinks();
  },
  computed: {
    
  }
}
</script>
